import React from 'react';
import {
  ContentElementDownload as ContentElementDownloadDefault,
  ContentElementDownloadProps,
} from '@vgn-medien-holding/vgn-fe-components';
import { ContentElementDownload as ContentElementDownloadType } from '@src/lib/graphql/generated';
import { IconDownload } from '@assets/icon-download';

export default function ContentElementDownloads(element: ContentElementDownloadType) {
  const styles: ContentElementDownloadProps['classProps'] = {
    root: 'lg:px-32',
    teaser: 'heading-6 mb-2',
    download: 'prose inline-block text-primary underline inline-flex gap-2',
  };

  if (!element?.files || element.files?.length < 1) return null;

  return (
    <ContentElementDownloadDefault
      element={element}
      classProps={styles}
      PreElement={<IconDownload classProps={{ root: 'inline mr-0.5 h-6 w-6 min-w-[1.5rem] mt-0.5' }} />}
    />
  );
}
