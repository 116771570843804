import React from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-react-components';
import { twMerge } from 'tailwind-merge';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { Genre } from '@components/atoms/Genre/Genre';
import { FavoriteButton } from '../FavoriteButton/FavoriteButton';
import { GrowingElement } from '../GrowingElement/GrowingElement';

export interface StreamingTeaserProps extends PropsWithClassProps<'main' | 'title' | 'image' | 'fallback'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entry: any;
  listicleType:
    | 'MOVIE'
    | 'SHOW'
    | 'CHANNEL'
    | 'SERIES'
    | 'EPISODE'
    | 'PERSON'
    | 'GENRE'
    | 'PROVIDER'
    | 'MANUAL'
    | 'COLLECTION_ENTRY';
  lastScroll?: number;
  showInitially?: boolean;
  showUpperBookmark?: boolean;
  favoriteRemovalConfirmation?: boolean;
}

const linkBase = {
  MOVIE: '/filme/',
  SHOW: '/serien/',
  GENRE: '/genres/',
  EPISODE: '',
};

export const StreamingTeaser = ({
  entry,
  listicleType,
  lastScroll,
  showInitially,
  showUpperBookmark,
  favoriteRemovalConfirmation,
  classProps,
}: StreamingTeaserProps) => {
  const entryType =
    listicleType && listicleType !== 'COLLECTION_ENTRY' ? listicleType : entry?.__typename?.toUpperCase();

  const teaserImage = entry?.backdrop_image || entry?.cover_image;
  const elementLinkBase = linkBase[entryType?.toUpperCase()] || '';

  if (!entry) return null;

  return (
    <GrowingElement
      link={elementLinkBase + '' + (entry.slug || entry.id)}
      key={entry?.id}
      lastScroll={lastScroll}
      showInitially={showInitially}
      topSlot={
        <>
          {teaserImage?.url ? (
            <Image
              src={teaserImage.url}
              alt={entry.title}
              copyright={teaserImage?.copyright_text}
              width={350}
              height={200}
              imageMode="cover"
              loading="lazy"
              decoding="async"
              classProps={{
                root: 'object-cover',
                container: twMerge('overflow-hidden absolute inset-0 rounded-t-lg', classProps?.image),
              }}
            />
          ) : (
            <FallbackCard
              classProps={{
                root: twMerge('dark absolute inset-0 overflow-hidden rounded-t-lg', classProps?.fallback),
              }}
            />
          )}
          <FavoriteButton
            id={entry.id}
            type={entry.__typename}
            loginFlowTitle="Speichere jetzt Deine Lieblingsserien und -filme"
            askBeforeRemove={favoriteRemovalConfirmation}
            classProps={{
              root: twMerge(
                'absolute top-4 right-4 canhover:opacity-0 canhover:group-hover:opacity-100 transition-opacity',
                !showUpperBookmark && 'canhover:invisible',
              ),
              iconWrapper: 'bg-gray-820/70',
              bookmarked: 'border-primary text-primary !opacity-100 visible',
            }}
          />
        </>
      }
      bottomSlot={
        <>
          <div className="mt-2 flex items-start justify-between gap-4 pb-2 transition-spacings duration-150 canhover:group-hover:mt-3 canhover:sm:group-hover:px-4">
            <div
              className={twMerge(
                'hyphenate line-clamp-3 font-herokid text-sm font-medium text-gray-900 dark:text-white md:text-base canhover:group-hover:line-clamp-1 canhover:group-hover:text-white',
                classProps?.title,
              )}
            >
              {entry.title}
            </div>
            {!showUpperBookmark && (
              <FavoriteButton
                id={entry.id}
                type={entry.__typename}
                askBeforeRemove={favoriteRemovalConfirmation}
                loginFlowTitle="Speichere jetzt Deine Lieblingsserien und -filme"
                classProps={{
                  root: 'opacity-0 transition-all duration-150 canhover:group-hover:opacity-100 -mb-1',
                }}
              />
            )}
          </div>
          <div className="mt-3 grid h-0 w-full grid-rows-[1fr,auto] overflow-hidden px-4 canhover:sm:group-hover:h-28">
            <div className="mt-1 flex flex-wrap content-start items-start gap-1 opacity-0 transition-all duration-150 canhover:group-hover:opacity-100">
              {entry.__typename !== 'Entity' &&
                entry.genres?.slice(0, 5).map((genre, idx) => <Genre key={genre.id + idx} genre={genre} />)}
            </div>
            <div className="flex items-center gap-2 pb-2 text-sm text-white">
              <span className="font-bold">{entry.year}</span>
              {entry.__typename !== 'Entity' && entry?.runtime > 0 && <span>•&nbsp;{entry.runtime}Min</span>}
              {entry.__typename === 'Movie' && entry?.age_rating && (
                <>
                  •&nbsp;<span className="border border-gray-600 p-1 leading-body">{entry.age_rating}</span>
                </>
              )}
            </div>
          </div>
        </>
      }
    />
  );
};
