import React from 'react';
import {
  composeStyles,
  ContentElementGallery,
  ContentElementGalleryProps,
} from '@vgn-medien-holding/vgn-fe-components';
import { CarouselProps } from '@vgn-medien-holding/vgn-fe-components';
import { ContentElementGallery as ContentElementGalleryType } from '@src/lib/graphql/generated';
import elementStyle from './styles/default';

const carouselStyle = {
  root: '',
  slider: '',
  navigation: 'mt-4 flex flex-wrap justify-center gap-4',
  navigationDot: 'w-2.5 h-2.5 bg-gray-400',
  navigationDotActive: 'bg-primary',
  arrowButtonContainerPrev: 'z-10 absolute left-0 inset-y-0 p-2 md:p-4 grid place-items-center',
  arrowButtonContainerNext: 'z-10 absolute right-0 inset-y-0 p-2 md:p-4 grid place-items-center',
  arrowButtonPrev: 'group',
  arrowButtonNext: 'group',
  arrowButtonIcon: 'bg-white text-black group-hover:text-primary transition-colors duration-300 border-none',
} as CarouselProps['classProps'];

export default function El(element: ContentElementGalleryType) {
  const carouselProps: ContentElementGalleryProps['carouselProps'] = {
    settings: {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      draggable: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      useCSS: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            centerPadding: '60px',
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            dots: false,
          },
        },
      ],
    },
    classProps: composeStyles(carouselStyle, {
      arrowButtonContainerPrev: 'left-0 md:left-8 lg:left-16',
      arrowButtonContainerNext: 'right-0 md:right-8 lg:right-16',
      navigation: 'max-w-[960px] mx-8 lg:mx-16 xl:mx-auto',
    }),
  };

  return (
    <div className="md:px-16 lg:px-32">
      <ContentElementGallery
        element={element}
        carouselProps={carouselProps}
        classProps={elementStyle}
        imageCardProps={{
          classProps: {
            infoWrapper: 'absolute top-0 right-0 flex flex-row-reverse p-2',
            imageContainClass: 'aspect-video',
            imageNoCropClass: 'aspect-auto',
            imageNoCrop50Class: 'w-1/2 mx-auto aspect-auto h-full',
            icon: 'text-white flex-shrink-0',
            textWrapper: 'bg-white mr-2 p-2 body-sm',
            textVisible: 'rounded-none prose-mobile-sm md:prose-sm bg-white p-2',
            root: 'bg-white',
            container: 'bg-white w-full relative',
            image: 'rounded-none bg-white w-full h-full',
            imageLink: 'overflow-hidden bg-white',
          },
          bgBlur: true,
        }}
      />
    </div>
  );
}
