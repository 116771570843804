import { ContentElementGalleryProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps = {
  root: '',
  imageCardRoot: 'scale-[0.65] opacity-60 transition-transform duration-300 origin-right my-6',
  imageCardRootLeft: 'origin-right',
  imageCardRootRight: 'origin-left',
  imageCardContainer: 'bg-white relative',
  image: 'rounded-none w-full h-full',
  imageTextVisible: 'opacity-0 rounded-none prose-xs md:prose-sm transition-opacity duration-300',
  activeImageCardRoot: 'scale-100 sc max-w-[960px] lg:mx-auto opacity-100',
  activeImageTextVisible: 'opacity-100',
  arrowButtonContainerPrev: 'mb-8 md:mb-16',
  arrowButtonContainerNext: 'mb-8 md:mb-16',
  arrowButtonLeft: 'right-0',
  arrowButtonRight: 'left-0',
} as ContentElementGalleryProps['classProps'];

export default classProps;
