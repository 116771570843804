import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { Genre } from '@components/atoms/Genre/Genre';
import { Provider } from '@components/atoms/Provider/Provider';
import { Channel } from '@components/molecules/Channel/Channel';
import { Person } from '@components/molecules/Person/Person';
import { StreamingTeaser } from '@components/molecules/StreamingTeaser/StreamingTeaser';
import { TvCurrentProgram } from '@components/molecules/TvCurrentProgram/TvCurrentProgram';

export interface ListicleItemProps extends PropsWithClassProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entry: any;
  listicleType:
    | 'MOVIE'
    | 'SHOW'
    | 'CHANNEL'
    | 'SERIES'
    | 'EPISODE'
    | 'PERSON'
    | 'GENRE'
    | 'PROVIDER'
    | 'MANUAL'
    | 'COLLECTION_ENTRY';
  lastScroll?: number;
  query?: string;
  primetime?: boolean;
  showInitially?: boolean;
}

export const ListicleItem = React.forwardRef<HTMLDivElement, ListicleItemProps>(
  ({ entry, listicleType, lastScroll, query, classProps, primetime, showInitially }) => {
    if (!entry) return;

    function isStreaming() {
      return ['MOVIE', 'SHOW', 'SERIES', 'EPISODE', 'COLLECTION_ENTRY'].includes(listicleType?.toUpperCase());
    }
    return isStreaming() ? (
      <StreamingTeaser
        lastScroll={lastScroll}
        entry={entry.full || entry}
        listicleType={listicleType}
        classProps={classProps}
        showInitially={showInitially}
      />
    ) : listicleType === 'CHANNEL' ? (
      <>
        {query === 'tv' || query === 'settings' ? (
          <TvCurrentProgram
            lastScroll={lastScroll}
            primetime={primetime}
            channel={entry}
            classProps={classProps}
            showInitially={showInitially}
          />
        ) : (
          <Channel channel={entry} classProps={{ root: 'w-36 h-36' }} />
        )}
      </>
    ) : listicleType === 'PROVIDER' ? (
      <Provider provider={entry} style="box" classProps={{ boxStyle: 'w-36 h-36' }} />
    ) : listicleType === 'GENRE' ? (
      <Genre genre={entry} type="medium" classProps={{ root: 'w-36 h-36' }} />
    ) : listicleType === 'PERSON' ? (
      <Person type="compact" person={entry} classProps={{ root: 'w-96' }} />
    ) : (
      <></>
    );
  },
);

ListicleItem.displayName = 'ListicleItem';
