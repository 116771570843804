import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { Image, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { isTomorrow } from 'date-fns';
import { format } from 'date-fns/format';
import { isToday } from 'date-fns/isToday';
import { twMerge } from 'tailwind-merge';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { Genre } from '@components/atoms/Genre/Genre';
import { createDate } from '@src/utils/dateHelpers';
import { getChannelLogo } from '@src/utils/sortedChannels';
import { GetChannelShowtimeNowDocument, TvChannel, TvChannelShowtime } from '@lib/graphql/generated';
import { useQuery } from '@lib/graphql/urql';
import { FavoriteButton } from '../FavoriteButton/FavoriteButton';
import { GrowingElement } from '../GrowingElement/GrowingElement';

const ShowtimeProgress = dynamic(() =>
  import('@components/molecules/ShowtimeProgress/ShowtimeProgress').then((mod) => mod['ShowtimeProgress']),
);

export interface TvCurrentProgramProps extends PropsWithClassProps {
  channel?: TvChannel & { showtimeData: TvChannelShowtime };
  channelEntry?: TvChannelShowtime;
  primetime?: boolean;
  showInitially?: boolean;
  showDate?: boolean;
  lastScroll?: number;
}

export const TvCurrentProgram = ({
  channel,
  channelEntry,
  showInitially,
  classProps,
  lastScroll,
  primetime = false,
  showDate,
}: TvCurrentProgramProps) => {
  const [inView, setInView] = useState(false);

  const pause = !!channel?.showtimeData?.id || !inView || !!channelEntry;
  const [showtimeNow, setShowtimeNow] = useState(undefined);

  const [{ fetching: showtimeLoading, data: channelData, stale }, refetch] = useQuery({
    query: GetChannelShowtimeNowDocument,
    variables: { id: channel?.id, primetime },
    pause,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const showtime = channel?.showtimeData || channelData?.channelShowtimeNow || channelEntry;
    if (!showtimeLoading && channelData) {
      setShowtimeNow(channel?.showtimeData || channelData?.channelShowtimeNow);
    } else if (channelEntry) {
      const startTime = showtimeNow?.start?.slice(11, 16);
      const endTime = showtimeNow?.stop?.slice(11, 16);
      setShowtimeNow({ ...showtime, start_time: startTime, stop_time: endTime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, channelData, showtimeLoading, channelEntry]);

  const channelLogo = useMemo(() => {
    return getChannelLogo(channel?.id || channelEntry?.channel?.id);
  }, [channel?.id, channelEntry?.channel?.id]);

  return (
    <GrowingElement
      showInitially={showInitially}
      classProps={classProps}
      lastScroll={lastScroll}
      key={showtimeNow?.id}
      onInViewChange={(inView) => setInView(inView)}
      link={`/programm/${showtimeNow?.event_id}`}
      topSlot={
        <>
          {showtimeNow?.image?.url ? (
            <Image
              src={showtimeNow?.image.url}
              alt={showtimeNow?.title}
              width={350}
              height={200}
              imageMode="cover"
              copyright=""
              classProps={{
                container: 'absolute inset-0 rounded-t-lg overflow-hidden',
                root: 'w-full',
              }}
            />
          ) : (
            <FallbackCard classProps={{ root: 'overflow-hidden rounded-t-lg' }} />
          )}

          <ShowtimeProgress
            showtime={showtimeNow}
            onShowEnded={() => {
              if (!showtimeLoading && !pause && !stale && !primetime) {
                refetch();
              }
            }}
            progressClassProps={{ root: 'absolute bottom-0 inset-x-0 z-10' }}
          />
          {channelLogo && (
            <div className="absolute right-3 top-2 h-5 w-8 rounded bg-gray-820/50 p-1">
              <div className="relative size-full">
                <Image
                  src={channelLogo}
                  alt={channel?.name || channelEntry?.channel?.name}
                  priority={true}
                  placeholder="empty"
                  width={48}
                  height={24}
                  copyright=""
                  classProps={{ root: 'h-auto max-h-full w-12 object-fit', container: 'flex items-center' }}
                />
              </div>
            </div>
          )}
        </>
      }
      bottomSlot={
        !!showtimeNow && (
          <>
            <div className="flex w-full justify-between gap-3 p-2 transition-spacings duration-150 canhover:group-hover:px-4 canhover:group-hover:pt-3">
              <div className="mt-1">
                {showtimeNow?.start && showtimeNow?.stop && (
                  <div className="text-sm text-gray-400">
                    {showDate &&
                      (isToday(createDate(showtimeNow?.start))
                        ? 'Heute'
                        : isTomorrow(createDate(showtimeNow?.start))
                          ? 'Morgen'
                          : format(createDate(showtimeNow?.start), 'dd.MM')) + ', '}
                    {format(createDate(showtimeNow?.start), 'HH:mm')}&nbsp;-&nbsp;
                    {format(createDate(showtimeNow?.stop), 'HH:mm')} Uhr
                  </div>
                )}
                <div
                  className={twMerge(
                    'hyphenate line-clamp-2 font-herokid text-sm font-medium text-gray-900 dark:text-white md:text-base canhover:group-hover:line-clamp-1 canhover:group-hover:text-white',
                  )}
                >
                  {showtimeNow?.title}
                </div>
              </div>
              <FavoriteButton id={showtimeNow?.event_id} type="TVChannelShowtime" classProps={{ root: 'mt-2' }} />
            </div>
            <div className="mt-2 grid h-0 w-full grid-rows-[1fr,auto] overflow-hidden px-4 canhover:sm:group-hover:h-28 canhover:sm:group-hover:pb-4">
              <div className="mt-1 flex flex-wrap content-start items-start gap-1">
                {showtimeNow.genre && <Genre genre={{ title: showtimeNow.genre, id: '01' }} />}
              </div>
              <div className="flex items-center gap-2 pb-2 text-sm text-white">
                <span className="font-bold">{showtimeNow.year}</span>
              </div>
            </div>
          </>
        )
      }
    />
  );
};
